import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { whr_blue, whr_orange } from "../assets/colours"

import Image from "gatsby-image"

const Layout = ({
  isHomePage,
  children,
  menuOpen,
  toggleMenu,
  fullWidthMenu,
}) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        themeOptions {
          themeOptions {
            themeLogo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            privacyPolicy
            alburyAddress {
              title
              url
            }
            contactEmail {
              title
              url
            }
            contactNumber {
              url
              title
            }
            torquayAddress {
              title
              url
            }
            facebook {
              title
              url
            }
            instagram {
              title
              url
            }
          }
        }
        generalSettings {
          title
          description
        }
      }
      wpMenu(locations: { eq: MENU_1 }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            title
            path
            parentId
            childItems {
              nodes {
                path
                label
                childItems {
                  nodes {
                    path
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [scrolled, setScrolled] = useState()

  useEffect(() => {
    const featuredImage = document.querySelector("main > *:first-of-type")

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    }

    const observer = new IntersectionObserver(entries => {
      setScrolled(entries[0].isIntersecting)
    }, options)
    observer.observe(featuredImage)

    return () => {
      observer.disconnect()
    }
  }, [])

  const menu = data.wpMenu.menuItems.nodes
    ? data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
    : null

  const themeLogo = {
    fluid:
      data.wp.themeOptions.themeOptions.themeLogo.localFile?.childImageSharp
        ?.fluid,
    alt: data.wp.themeOptions.themeOptions.themeLogo.alt || ``,
  }

  return (
    <Page data-is-root-path={isHomePage}>
      <Header scrolled={scrolled} className={fullWidthMenu ? "full" : "half"}>
        <Link to="/" aria-label="Click this logo to return home">
          <Image fluid={themeLogo.fluid} alt={themeLogo.alt} />
        </Link>

        <MenuToggle
          onClick={toggleMenu}
          aria-label="Click this button to toggle the menu"
          className="closed"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>
      </Header>

      <Main menuState={menuOpen} toggleMenu={toggleMenu}>
        {children}
      </Main>

      <Footer>
        <div>
          <div>
            <p>
              WHR Allied Health is part of the Work Health Rehab Group PTY LTD.
            </p>
            <p className="mb">
              We're are a proudly NDIS Registered Provider, registration ID:
              4-JXLBERV,
            </p>
            <p className="mb">40 Baines Crescent Torquay, Vic, 3228</p>
            <p className="mb mb--lg">
              <strong>ACN: </strong>662543115
            </p>
            <p className="text-sm">
              © WHR Allied Health {new Date().getFullYear()}
            </p>
          </div>
          <div>
            <img src={"/round_logo.png"} alt="Logo" />
          </div>
        </div>
      </Footer>

      <Menu className={menuOpen ? "open" : "closed"}>
        <MenuToggle
          onClick={toggleMenu}
          aria-label="Click this button to toggle the menu"
          className="open"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>
        <ul className="links">
          {menu.map((menuItem, index) => {
            const children = menuItem.childItems.nodes.length
              ? menuItem.childItems.nodes
              : null

            return children ? (
              <ParentItem key={index} parent={menuItem} children={children} />
            ) : (
              <MenuItem key={index} menuItem={menuItem} />
            )
          })}
        </ul>
        <div className="contact">
          <address>
            <a
              target="_blank"
              rel="noreferrer"
              href={data.wp.themeOptions.themeOptions.alburyAddress.url}
            >
              {data.wp.themeOptions.themeOptions.alburyAddress.title}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={data.wp.themeOptions.themeOptions.torquayAddress.url}
            >
              {data.wp.themeOptions.themeOptions.torquayAddress.title}
            </a>
          </address>
          <div className="email-phone">
            <a href={data.wp.themeOptions.themeOptions.contactEmail.url}>
              {data.wp.themeOptions.themeOptions.contactEmail.title}
            </a>
            <a href={data.wp.themeOptions.themeOptions.contactNumber.url}>
              {data.wp.themeOptions.themeOptions.contactNumber.title}
            </a>
          </div>
          <div className="social">
            <a
              target="_blank"
              rel="noreferrer"
              href={data.wp.themeOptions.themeOptions.facebook.url}
            >
              {data.wp.themeOptions.themeOptions.facebook.title}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={data.wp.themeOptions.themeOptions.instagram.url}
            >
              {data.wp.themeOptions.themeOptions.instagram.title}
            </a>
          </div>
        </div>
        <div class="about">
          <p>
            WHR Allied Health is part of the Work Health Rehab Group PTY LTD.
          </p>
          <p className="mb">
            We're are a proudly NDIS Registered Provider, registration ID:
            4-JXLBERV,
          </p>
          <p className="mb">40 Baines Crescent Torquay, Vic, 3228</p>
          <p className="mb">
            <strong>ACN: </strong>662543115
          </p>
          <p className="text-sm">
            © WHR Allied Health {new Date().getFullYear()}
          </p>
        </div>
      </Menu>
    </Page>
  )
}

const MenuItem = ({ menuItem, currentPage }) => (
  <li className="menu-item">
    <a className={menuItem.currentPage ? "current" : ""} href={menuItem.path}>
      {menuItem.label}
    </a>
  </li>
)

const ParentItem = ({ parent, children }) => {
  const [show, setShow] = useState(false)

  return (
    <li className={`parent menu-item`} onClick={() => setShow(!show)}>
      <span className={show ? "open" : "closed"}>{parent.label}</span>

      <ul>
        {children.map((child, index) => (
          <li key={index} style={{ display: show ? "block" : "none" }}>
            <Link to={child.path}>{child.label}</Link>
          </li>
        ))}
      </ul>
    </li>
  )
}

const Main = styled.main`
  min-height: 100vh;
`

const Footer = styled.footer`
  background: ${whr_blue};
  padding: 4.5rem 1.5rem;
  @media (min-width: 48rem) {
    padding: 4.5rem 3rem;
  }
  color: white;

  > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 3rem;
    padding-right: 2rem;

    @media (min-width: 48rem) {
      width: 50%;
      padding-right: 3rem;
    }

    @media (min-width: 100rem) {
      flex-direction: row;
      width: 50%;
      gap: 1.5rem;
      padding-right: 4.5rem;
    }

    justify-content: space-between;
  }

  p.mb {
    margin-bottom: 1rem;
  }

  p.mb--lg {
    margin-bottom: 4rem;
  }

  p.text-sm {
    font-size: 0.75rem;
  }

  img {
    aspect-ratio: 1 / 1;
    object-fit: contain;
    width: 8rem;
    display: flex;
    @media (min-width: 100rem) {
      margin-left: auto;
    }
  }
`

const Page = styled.div`
  p.mb {
    margin-bottom: 1rem;
  }

  p.mb--lg {
    margin-bottom: 4rem;
  }
  nav > .gatsby-image-wrapper > div {
    display: none;
  }

  nav .gatsby-image-wrapper > img {
    position: static !important;
  }

  nav .gatsby-image-wrapper img {
    object-fit: contain !important;
    height: auto;
    width: 12.5rem !important;

    @media (min-width: 48rem) {
      width: 15rem !important;
    }
  }
`

const Header = styled.nav`
  &.half {
    @media (min-width: 0px) {
      padding: 12px 24px;
      ${props => {
        if (!props.scrolled) {
          return css`
            background: ${whr_blue};
            transition: background 0.4s;
          `
        }
      }}
    }
    @media (min-width: 992px) {
      padding-left: calc(50% + 48px);
      padding-right: 48px;
      background: transparent;
    }
  }

  width: 100%;
  padding: 24px;

  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuToggle = styled.button`
  cursor: pointer;
  padding-top: 18px;
  height: 27px;
  width: 45px;
  position: relative;
  border: 0;
  border-radius: 0;
  background: transparent;
  position: relative;

  &.open {
    span {
      background-color: white;

      &:nth-of-type(1) {
        top: calc(50% - 2px);
        transform: rotate(45deg);
        transition: 0.7s;
      }
      &:nth-of-type(2) {
        top: calc(50% - 2px);
        transform: rotate(-45deg);
        transition: 0.7s;
      }
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        display: none;
      }
    }
  }

  span {
    position: absolute;
    height: 3px;
    left: 0;
    width: 100%;
    background-color: white;

    transition: 0.7s;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 9px;
    }
    &:nth-of-type(3) {
      top: 18px;
    }
    &:nth-of-type(4) {
      top: 27px;
    }
  }
`

const Menu = styled.div`
  .links {
    padding-left: 0;
  }

  > button {
    position: absolute;
    top: 36px;
    right: 24px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  a {
    transition: 0.7s;
    &:hover {
      color: ${whr_blue};
      transition: 0.7s;
    }
  }

  .about {
    p,
    strong {
      font-family: "Karla", sans-serif;
      font-size: 0.75rem;
    }
  }

  .contact {
    address {
      font-style: normal;
      margin: 24px 0px;
    }

    .social {
      margin-top: 24px;
    }

    a {
      display: block;
    }

    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0;
    }
  }

  @media (min-width: 0px) {
    width: 100vw;
    right: -100vw;
  }
  @media (min-width: 767px) {
    width: 50vw;
    right: -50vw;
  }
  @media (min-width: 1200px) {
    width: 35vw;
    right: -35vw;
  }

  position: fixed;
  top: 0;
  height: 100%;

  background: ${whr_orange};

  transition: 0.7s;
  z-index: 100;
  @media (min-width: 0px) {
    padding: 96px 24px 48px 24px;
  }
  @media (min-width: 768px) {
    padding: 96px 48px 48px 48px;
  }

  * {
    font-family: "Bebas Neue", sans-serif;
    color: white;
    text-decoration: none;
    font-size: 18px;
  }

  ul,
  li {
    list-style-type: none;
    font-size: 32px;

    a {
      display: block;
      color: white;
      font-size: inherit;
    }
  }

  &.open {
    right: 0;
    transition: 0.7s;
  }

  .menu-item {
    &:before {
      display: none;
    }

    margin-bottom: 8px;

    width: max-content;

    a {
      width: max-content;
    }
  }

  .parent {
    cursor: pointer;

    ul {
      margin-left: 0;
      margin-bottom: 0;

      li {
        &:before {
          display: none;
        }
        margin-bottom: 4px !important;
      }
    }

    > span {
      transition: 0.7s;

      &:hover {
        color: ${whr_blue};
        transition: 0.7s;

        &:after {
          border-top-color: ${whr_blue};
          transition: 0.7s;
        }
      }

      display: flex;
      align-items: center;
      font-size: 32px;
      &:after {
        display: inline-block;
        content: "";
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 13px solid white;
        margin-left: 16px;
        transition: 0.5s;
        padding-bottom: 4px;
      }
      &.open {
        &:after {
          transform: rotate(-180deg);
          transition: 0.5s;
        }
      }
    }

    a {
      margin-left: 12px;
      font-size: 24px;
    }
  }
`

export default Layout
